<template>
  <v-container fluid>
    <v-row>
      <!-- <v-col cols="12" style="margin-top: 1000px"></v-col> -->
      <v-col cols="12" class="d-flex justify-center">
        <v-card id="section-to-print" class="flex-column text-center pa-0" flat>
          <div width="100%"></div>
          <v-card flat>
            <span v-if="language === 'English'" style="font-family: 'PingFang TC', sans-serif; font-size: 20.3px !important; color: black; font-weight: 600">AIA</span>
            <v-card-text
              v-if="language === 'English'"
              class="pb-1 pt-0"
              style="font-family: 'PingFang TC', sans-serif; font-size: 20.3px !important; color: black; text-decoration: underline; text-underline-offset: 2.5px; font-weight: 600"
            >
              {{ center_name_en }}
            </v-card-text>
            <v-card-text
              v-else
              class="pb-1 pt-2 px-0"
              style="font-family: 'PingFang TC', sans-serif; font-size: 26px !important; color: black; text-decoration: underline; text-underline-offset: 2.5px; font-weight: 600"
            >
              {{ language === 'Mandarin' ? center_name_cn : center_name_zh }}
            </v-card-text>
            <v-card-subtitle v-if="language === 'English'" class="pt-1 pb-1" style="font-family: 'PingFang TC', sans-serif; font-size: 18px !important; color: black; font-weight: 500">
              Check Ticket Status Online
            </v-card-subtitle>
            <v-card-subtitle v-else class="pb-1" style="font-family: 'PingFang TC', sans-serif; font-size: 24px !important; color: black; font-weight: 500">
              {{ language === 'Mandarin' ? '网上票号查询' : '網上票號查詢' }}
            </v-card-subtitle>
          </v-card>
          <qrcode-vue :value="qr_value" size="130" class="py-3"></qrcode-vue>
          <v-card flat>
            <v-card-text class="pt-1 pb-3 text-uppercase" style="font-family: 'PingFang TC', sans-serif; font-size: 42px !important; color: black; font-weight: 500">
              {{ code }}
            </v-card-text>
            <v-card-subtitle class="py-0 text-capitalize" style="font-family: 'PingFang SC', sans-serif; font-size: 24px !important; color: black; font-weight: 500">
              {{ service_type }}
            </v-card-subtitle>
          </v-card>
          <v-card class="d-flex flex-no-wrap justify-space-between rounded-0 px-2 ma-4 mb-1" :class="language === 'English' ? 'py-3' : 'py-4'" outlined style="border: 1px solid black">
            <div>
              <v-card-title
                v-if="language === 'English'"
                class="text-left px-0 pt-0"
                style="font-family: 'PingFang SC', sans-serif; font-size: 10px !important; color: black; font-weight: 600; line-height: normal"
                v-html="'AIA Connect<br>Easy to Use.<br>Easy to Connect.<br>Easy to Transact.'"
              >
              </v-card-title>
              <v-card-title
                v-else
                class="text-left px-0 pt-0"
                style="font-family: 'PingFang SC', sans-serif; font-size: 12px !important; color: black; font-weight: 500; line-height: normal"
                v-html="language === 'Mandarin' ? 'AIA Connect 友联系<br />简易连结你需要' : 'AIA Connect 友聯繫<br />簡易連結你需要'"
              >
              </v-card-title>
              <v-card-subtitle
                v-if="language === 'English'"
                class="text-left px-0 pb-0 pt-1"
                style="font-family: 'PingFang SC', sans-serif; font-size: 9.5px !important; color: black; width: 7.9rem; word-break: break-word; line-height: normal"
              >
                One app, one Easy Login ID to access all your AIA accounts, from individual life insurance, group insurance, MPF/ ORSO/ Macau Pension to AIA Vitality.
              </v-card-subtitle>
              <v-card-subtitle
                v-else
                class="text-left px-0 pb-0 pt-1"
                style="font-family: 'PingFang SC', sans-serif; font-size: 10px !important; color: black; width: 7.9rem; word-break: break-word; line-height: normal"
                v-html="
                  language === 'Mandarin'
                    ? '一个流动程式管理您所有友邦账户，包括个人保险、团体保险、强积金/公积金/澳门退休金及「AIA Vitality 健康程式」。'
                    : '一個流動程式管理您所有友邦賬戶，包括個人保險、團體保險、強積金/公積金/澳門退休金及「AIA Vitality 健康程式」。'
                "
              >
              </v-card-subtitle>
            </div>
            <v-avatar tile size="100" :class="language === 'English' ? 'mt-2' : ''">
              <v-img src="@/assets/images/ticket/app_qr_code.png" id="aia_qr_code"></v-img>
            </v-avatar>
          </v-card>
          <span style="font-family: 'PingFang SC', sans-serif; font-size: 24px !important; color: black">{{ create_datetime | moment('YYYY-MM-DD hh:mm:ss') }}</span>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import api from '@/apis'
import QrcodeVue from 'qrcode.vue'

export default {
  name: 'Ticket',
  components: { QrcodeVue },
  data() {
    return {
      center_name_en: process.env.VUE_APP_CENTER_NAME,
      center_name_zh: process.env.VUE_APP_CENTER_NAME_ZH,
      center_name_cn: process.env.VUE_APP_CENTER_NAME_CN,

      info: null,
      language: null,
      code: null,
      service_type: null,
      create_datetime: null
    }
  },
  methods: {
    async fetchTicketInfo() {
      try {
        this.service_type = this.$route.params.service_type

        const response = await api.getTicketInfo(this.$route.params.ticket_id)
        if (response.data.success) {
          if (response.data.data.length) {
            const ticket_info = response.data.data[0]
            // Check whether the ticket is today's ticket
            if (this.$moment().isSame(ticket_info.create_datetime, 'day')) {
              this.info = ticket_info
            } else throw Error('Invalid ticket')
          } else throw Error('No such ticket exist')
        } else throw Error(response.data.message)
      } catch (error) {
        window.close()
      }
    },
    printTicket() {
      window.print()
      window.onfocus = function() {
        setTimeout(function() {
          window.close()
        }, 500)
      }
    }
  },
  watch: {
    info: {
      deep: true,
      async handler() {
        if (this.info) {
          this.code = this.info.code
          this.language = this.info.language
          this.create_datetime = this.info.create_datetime
          try {
            const response = await api.getServiceTypeByID(this.info.service_type_id)
            if (response.data.success) {
              if (response.data.data.length) {
                const service = response.data.data[0]
                this.service_type = this.language === 'Cantonese' ? service.name : this.language === 'English' ? service.name_en : service.name_cn
                setTimeout(() => this.printTicket(), 500)
              } else throw Error('No such service type exist')
            } else throw Error(response.data.message)
          } catch (error) {
            window.close()
          }
        }
      }
    }
  },
  computed: {
    qr_value() {
      const language = this.language === 'Cantonese' ? 'zhHant' : this.language === 'Mandarin' ? 'zhHans' : 'en'
      return process.env.VUE_APP_TICKET_QUERY_ENDPOINT + `/ticket/${language}?query=${this.$route.params.ticket_id}`
    }
  },
  created() {
    this.fetchTicketInfo()
  }
}
</script>

<style scoped>
/* @import url('https://fonts.googleapis.com/css?family=PingFang+TC&display=swap');
@import url('https://fonts.googleapis.com/css?family=PingFang+SC&display=swap'); */
@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

@media print {
  * {
    -webkit-print-color-adjust: exact;
  }
  @page {
    size: auto; /* auto is the initial value */
    margin: 0mm; /* this affects the margin in the printer settings */
  }

  html {
    background-color: #ffffff;
    margin: 0px; /* this affects the margin on the html before sending to printer */
  }

  body {
    border: solid 1px blue;
    margin: 10mm 15mm 10mm 15mm; /* margin you want for the content */
  }
  body * {
    visibility: hidden;
  }
  #section-to-print,
  #section-to-print * {
    visibility: visible;
  }
  #section-to-print {
    position: absolute;
    left: 0;
    top: 0;
    width: 300px;
    height: 300px;
    z-index: 15;
    left: 50%;
    margin: 0 0 0 -150px;
  }
}
</style>
